.header{
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.inputSearch{
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    outline: none;
    border: 1px solid grey;
}